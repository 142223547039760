<template>
  <v-card
    outlined
    class="d-flex flex-column"
    :style="cssProps"
  >
    <v-card-title>
      <h5>Akquise-Booster</h5>
      <v-spacer />
      <v-btn
        icon
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-if="landingpageTemplates && landingpages">
      <p>
        Verwandeln Sie schlummernde Kontakte in neue Leads.
      </p>
      <div
        class="mt-4 rounded-lg mx-n2 pa-2"
        :class="{'finished-step':landingpageSlug}"
      >
        <div
          class="d-flex"
        >
          <v-icon
            v-if="!landingpageSlug"
            :class="{'blink':!landingpageSlug}"
            small
          >
            mdi-arrow-right
          </v-icon>
          <h4 :class="{'blink':!landingpageSlug}">
            Schritt 1: Thema auswählen
          </h4>
          <v-icon
            v-if="landingpageSlug"
            class="ml-2"
            size="20"
            small
            color="primary"
          >
            mdi-check-circle
          </v-icon>
        </div>
        <p>Mit welchem Thema wollen Sie Ihre Kontakte ansprechen?</p>
        <v-select
          v-model="landingpageSlug"
          class="mt-2"
          outlined
          validate-on-blur
          :items="landingpageSlugs"
          item-value="slug"
          item-text="name"
          placeholder="Themenauswahl"
          hide-details
          dense
          required
        />
      </div>

      <div
        class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
        :class="{'finished-step':isStepContactFilterFinished}"
      >
        <div
          class="d-flex"
        >
          <v-icon
            v-if="isStepContactFilter"
            :class="{'blink':isStepContactFilter}"
            small
          >
            mdi-arrow-right
          </v-icon>
          <h4 :class="{'blink':isStepContactFilter}">
            Schritt 2: Kontakte auswählen
          </h4>

          <v-icon
            v-if="isStepContactFilterFinished"
            class="ml-2"
            size="20"
            small
            color="primary"
          >
            mdi-check-circle
          </v-icon>
        </div>
        <p>Welche Kontakte möchten Sie einladen?</p>
        <v-select
          v-model="contactFilter"
          class="mt-2 mb-2"
          outlined
          validate-on-blur
          :items="contactFilters"
          :disabled="!landingpageSlug"
          placeholder="Kontaktauswahl"
          hide-details
          dense
          required
        />

        <slot name="selectAll" />
      </div>
      <div
        class="mt-4 mb-2 rounded-lg mx-n2 pa-2"
        :class="{'finished-step':isSubmitted}"
      >
        <div class="d-flex">
          <v-icon
            v-if="isStepSubmit"
            :class="{'blink':isStepSubmit}"
            small
          >
            mdi-arrow-right
          </v-icon>
          <h4 :class="{'blink':isStepSubmit}">
            Schritt 3: E-Mail absenden
          </h4>
          <v-icon
            v-if="isSubmitted"
            class="ml-2"
            size="20"
            small
            color="primary"
          >
            mdi-check-circle
          </v-icon>
        </div>
        <p>
          Eine starke E-Mail geht an die ausgewählten Kontakte. So wissen alle über Ihre neuen Services Bescheid. Das ist der Booster für Ihre Akquise!
        </p>

        <v-btn
          v-if="maxContactsSelected"
          depressed
          small
          color="info"
          :disabled="selectedItemsLength<=0"
          class="w-full"
          @click="submit"
        >
          <v-icon
            small
            class="mr-2"
          >
            mdi-information-outline
          </v-icon>
          Akquise-Booster starten
        </v-btn>

        <p
          v-if="maxContactsSelected"
          class="info--text pt-2"
        >
          ACHTUNG! Der Versand an mehr als  <strong>{{ maxContactsToMail }} Kontakte</strong> kann eine hohe Resonanzrate zur Folge haben.
          Dies kann erheblichen Aufwand in der Bearbeitung und Rückverfolgung nach sich ziehen.
        </p>
        <v-btn
          v-else
          depressed
          small
          color="primary"
          :disabled="selectedItemsLength<=0"
          class="w-full"
          @click="submit"
        >
          <v-icon
            small
            class="mr-2"
          >
            mdi-rocket-launch
          </v-icon>
          Akquise-Booster starten
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import invitationMixin from '@/mixins/invitation'
import bus, { eventNames } from '@/lib/eventBus'
import { InvitationContactFilter, ReadableInvitationContactFilter } from '../enums/Invitation'
import { persistFilter, resetAllFilters } from '@/modules/leads/helper/filter'

export default {
  mixins: [invitationMixin],
  props: {
    selectedItemsLength: {
      type: Number,
      default: 0
    },
    maxContactsToMail: {
      type: Number,
      default: 1000
    },
    triggerResetAcquisitionBooster: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contactFilter: null,
      isSubmitted: false
    }
  },
  computed: {
    contactFilters () {
      const readableInvitationContactFilter = Object.keys(ReadableInvitationContactFilter).map(key => ({ value: key, text: ReadableInvitationContactFilter[key] }))
      if (this.$vuetify.breakpoint.smAndDown) { return readableInvitationContactFilter.filter(filter => filter.value !== InvitationContactFilter.CUSTOM) }
      return readableInvitationContactFilter
    },
    cssProps () {
      return {
        '--primary': this.$vuetify.theme.themes.light.primary,
        '--primary-light': `${this.$vuetify.theme.themes.light.primary}55`
      }
    },
    isStepContactFilterFinished () {
      return this.landingpageSlug && this.contactFilter && this.selectedItemsLength > 0
    },
    isStepContactFilter () {
      return this.selectedItemsLength <= 0 && this.landingpageSlug
    },
    isStepSubmit () {
      return this.selectedItemsLength > 0 && this.landingpageSlug && this.contactFilter
    },
    maxContactsSelected () {
      return this.selectedItemsLength >= this.maxContactsToMail
    }
  },

  watch: {
    triggerResetAcquisitionBooster () {
      if (this.triggerResetAcquisitionBooster) {
        this.reset()
        this.$emit('triggeredResetAcquisitionBooster')
      }
    },
    landingpageSlug () {
      this.isSubmitted = false
      if (this.contactFilter) {
        this.$emit('updateInvitation', { contactFilter: this.contactFilter, landingpageSlug: this.landingpageSlug })
      }
    },
    contactFilter () {
      this.isSubmitted = false
      if (this.contactFilter !== InvitationContactFilter.CUSTOM) {
        resetAllFilters()
        if (this.contactFilter === InvitationContactFilter.PROPERTY_REQUEST) {
          persistFilter('propertyRequests', true)
        } else if (this.contactFilter === InvitationContactFilter.VALUATIONS) {
          persistFilter('valuations', true)
        } else if (this.contactFilter === InvitationContactFilter.NEW) {
          persistFilter('currentStatus', 'new')
        }
      }
      this.$emit('updateInvitation', { contactFilter: this.contactFilter, landingpageSlug: this.landingpageSlug })
    }
  },

  methods: {
    reset () {
      this.landingpageSlug = ''
      this.contactFilter = null
      this.isSubmitted = false
    },
    close () {
      this.$emit('close')
    },
    submit () {
      bus.$emit(eventNames.LEAD_INVITED, { slug: 'invite-contact' })
      this.isSubmitted = true
      this.$emit('sendInvitation')
    }

  }
}
</script>

<style scoped>
.finished-step{
  border: 1px solid var(--primary-light);
  background: var(--primary-light);
}

.blink{
  animation: blink-animation 4s linear infinite;
  -webkit-animation: blink-animation 4s linear infinite;
}

.-mt-4 {
  margin-top: -4px;
}

@keyframes blink-animation {
  0% {
    color: rgba(0, 0, 0, 0.6);
  }

  50% {
    color: var(--primary);
  }

  100% {
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>
